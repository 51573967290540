<template>
  <div>
    <div class="row">
      <div class="col-12 pl-0">
        <span class="float-right mt-2">
          <button class="btn btn-xs btn-dark font-weight-bold e-text-white" type="button" v-if="isElite()" @click="redirectToUserList">
            <font-awesome-icon icon="chevron-left" class="e-text-black"/> Go Back
          </button>
        </span>
        <h3 class="text-left">User Profile</h3>
        <!--        <div class="row">-->
<!--          <div class="col-lg-10">-->
<!--            <h4>User Profile</h4>-->
<!--          </div>-->
<!--          <div class="col-lg-2 text-right">-->
<!--            <router-link :to="{name: 'SettingsUsers'}" class="e-btn e-btn-sm e-btn-outlined-blue-gray e-text-blue-gray e-text-white-hover">-->
<!--              <font-awesome-icon icon="chevron-left"/> Go Back-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
        <div class="card p-3 shadow-sm">
          <form @submit.prevent="updateUser" @keydown="currentUser.errors.clear($event.target.name);">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <h5 class="e-text-red">
                      {{ currentUser ? currentUser.name : '' }}
                    </h5>
                  </div>
                  <div class="col-auto">
                    <button class="e-btn e-btn-outlined-red e-text-red e-text-white-hover mr-1" type="button" v-if="toEdit" @click="cancelEdit">
                      <font-awesome-icon icon="ban" class="e-text-red"/>
                      Cancel
                    </button>
                    <button class="e-btn e-btn-outlined-orange e-text-orange e-text-white-hover" v-else type="button" @click="editUser">
                      <font-awesome-icon icon="pen" class="e-text-orange"/>
                      Edit User
                    </button>
                    <button class="e-btn e-btn-green e-text-white ml-1" type="submit" v-if="toEdit" :disabled="currentUser.errors.any()">
                      <font-awesome-icon icon="save"/>
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 align-self-start">
                <div class="card">
                  <div class="card-body p-1">
                    <img :src="staffIcon" alt="Staff Default Icon">
                  </div>
                </div>
              </div>
              <div class="col-lg-10 p-lg-0">
                <div class="row align-items-center no-gutters my-1">
                  <div class="col-12">
                    <h6 class="mb-0 font-weight-bold e-text-red">Personal Information</h6>
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">First Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.first_name" name="first_name" class="form-control form-control-sm" required :class="{ 'is-invalid': currentUser.errors.has('first_name') }">
                    <div v-if="currentUser.errors.has('first_name')" :class="{ 'invalid-feedback': currentUser.errors.has('first_name') }">
                      {{ currentUser.errors.first('first_name') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.first_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Last Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.last_name" name="last_name" class="form-control form-control-sm" required :class="{ 'is-invalid': currentUser.errors.has('last_name') }">
                    <div v-if="currentUser.errors.has('last_name')" :class="{ 'invalid-feedback': currentUser.errors.has('last_name') }">
                      {{ currentUser.errors.first('last_name') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.last_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Middle Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.middle_name" name="middle_name" class="form-control form-control-sm" :class="{ 'is-invalid': currentUser.errors.has('middle_name') }">
                    <div v-if="currentUser.errors.has('middle_name')" :class="{ 'invalid-feedback': currentUser.errors.has('middle_name') }">
                      {{ currentUser.errors.first('middle_name') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.middle_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Suffix:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.extension" name="extension" class="form-control form-control-sm" :class="{ 'is-invalid': currentUser.errors.has('extension') }">
                    <div v-if="currentUser.errors.has('extension')" :class="{ 'invalid-feedback': currentUser.errors.has('extension') }">
                      {{ currentUser.errors.first('extension') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.extension : '' }}
                  </div>
                </div>

                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Rank:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="rank_id" v-model="currentUser.rank_id" @change="changeManagementOffice($event)"
                            :class="{ 'is-invalid': currentUser.errors.has('rank_id') }">
                      <option :value="null">—— Select Rank ——</option>
                      <option v-for="rank in userRanksKeys" :key="rank.id" :value="rank.id">
                        {{ rank.name }}
                      </option>
                    </select>
                    <div v-if="currentUser.errors.has('rank_id')" :class="{ 'invalid-feedback': currentUser.errors.has('rank_id') }">
                      {{ currentUser.errors.first('rank_id') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (currentUser && currentUser.rank) ? currentUser.rank.name : '' }}
                  </div>
                </div>

                <div class="row align-items-center no-gutters mt-2 mb-1">
                  <div class="col-12">
                    <h6 class="mb-0 font-weight-bold e-text-red">Company Details</h6>
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Username:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.username" name="username" class="form-control form-control-sm" required :class="{ 'is-invalid': currentUser.errors.has('username') }">
                    <div v-if="currentUser.errors.has('username')" :class="{ 'invalid-feedback': currentUser.errors.has('username') }">
                      {{ currentUser.errors.first('username') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.username : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Email Address:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="currentUser.email" name="email" class="form-control form-control-sm" required :class="{ 'is-invalid': currentUser.errors.has('email') }">
                    <div v-if="currentUser.errors.has('email')" :class="{ 'invalid-feedback': currentUser.errors.has('email') }">
                      {{ currentUser.errors.first('email') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ currentUser ? currentUser.email : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Management Office:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="management_office_id" v-model="currentUser.management_office_id" @change="changeManagementOffice($event)"
                            :class="{ 'is-invalid': currentUser.errors.has('management_office_id') }" :disabled="disable.managementOffice">
                      <option :value="null">—— Select Management Office ——</option>
                      <option v-for="mngmntOffice in mngmntOfficeKeys" :key="mngmntOffice.id" :value="mngmntOffice.id">
                        {{ mngmntOffice.name }}
                      </option>
                    </select>
                    <div v-if="currentUser.errors.has('management_office_id')" :class="{ 'invalid-feedback': currentUser.errors.has('management_office_id') }">
                      {{ currentUser.errors.first('management_office_id') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (currentUser && currentUser.management_office) ? currentUser.management_office.name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Vessel:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="vessel_id" v-model="currentUser.vessel_id" @change="changeVessel($event)"
                            :class="{ 'is-invalid': currentUser.errors.has('vessel_id') }" :disabled="disable.vessel">
                      <option :value="null">—— Select Vessel ——</option>
                      <option v-for="vessel in vesselKeys" :key="vessel.id" :value="vessel.id">
                        {{ vessel.name }}
                      </option>
                    </select>
                    <div v-if="currentUser.errors.has('vessel_id')" :class="{ 'invalid-feedback': currentUser.errors.has('vessel_id') }">
                      {{ currentUser.errors.first('vessel_id') }}
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (currentUser && currentUser.vessel) ? currentUser.vessel.name : '' }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pl-0">
        <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item" v-if="isElite()">
                <router-link class="nav-link" :to="{ name: 'RoleAndPermission'}">
                  <span>Roles & Permission</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="$route.params.id == $store.getters.user.id">
                <router-link class="nav-link" :to="{ name: 'ChangePassword'}">
                  <span>Change Password</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="isElite()">
                <router-link class="nav-link" :to="{ name: 'UserVessels'}">
                  <span>Vessels</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="isElite()">
                <router-link class="nav-link" :to="{ name: 'ResetPassword'}">
                  <span>Reset Password</span>
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
        <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AppLoading from "@/components/elements/AppLoading";
import {UserService} from "@/services/UserService";
import {Form} from "form-backend-validation";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import {permissionMixin} from "@/mixins/permissionMixins";
import userMixin from "@/mixins/UserMixin";

export default {
  name: "SettingsUserProfile",
  mixins:[permissionMixin, userMixin],
  data () {
    return {
      staffIcon: require('@/assets/staff_icon.png'),
      loading: false,
      editCurrentUser: {},
      currentUser: null,
      toEdit: false,
      disable: {
        managementOffice: false,
        vessel: false,
      }
    }
  },

  methods: {
    ...mapActions(['getMngmtOfficeKeys', 'getVesselKeys', 'defineSelectedUser']),
    redirectToUserList() {
      return this.$router.push({name: 'SettingsUsers'});
    },
    cancelEdit() {
      this.toEdit = false
      this.currentUser = Object.assign({}, this.editCurrentUser)
    },
    async setUser() {
      const data = await this.defineSelectedUser(this.$route.params.id)
      this.currentUser = new Form(data);
    },
    editUser() {
      this.editCurrentUser = Object.assign({}, this.currentUser);
      this.toEdit = true
    },
    changeManagementOffice(event) {
      if (this.currentUser.management_office_id) {
        this.currentUser.vessel_id = null
        this.disable.vessel = true
      } else {
        this.disable.vessel = false
      }

      this.currentUser.errors.clear(event.target.name)
    },
    changeVessel(event) {
      if (this.currentUser.vessel_id) {
        this.currentUser.management_office_id = null
        this.disable.managementOffice = true
      } else {
        this.disable.managementOffice = false
      }

      this.currentUser.errors.clear(event.target.name)
    },
    async updateUser() {
      if (await AlertService.confirmUpdateAlert()) {
        UserService.updateUser(this.currentUser)
          .then(response => {
            this.toEdit = false
            this.setUser()
            AlertService.successAlert('User has been updated', 'UPDATE USER')
          })
          .catch(error => {
            AlertService.errorAlert(error.response.data.message, 'UPDATE USER')
          });
      }
    },
  },

  components: {
    'loading': AppLoading,
  },

  computed: {
    ...mapGetters(['mngmntOfficeKeys', 'vesselKeys'])
  },

  async created() {
    await this.getMngmtOfficeKeys()
    await this.getVesselKeys()
    await this.setUser()
  },
}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;
  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}
.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;
  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}
table tbody td {
  vertical-align: middle !important;
}

table tbody td label {
  margin-bottom: 0 !important;
}
#ModelNav {
  padding: 0;
  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }
  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}
.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}
</style>
